import httpClient from '@/utils/httpClient';
import * as Sentry from '@sentry/vue';
import {saveAs} from 'file-saver';
import {localFeatures} from "@/utils/constants";
import {sendTelemetry} from "@/utils";

const apiRequests = {
  getCurrentActiveAuction: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('active-auction-live')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getCurrentActiveAuction", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getCurrentActiveAuction", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  getAuctionArticles: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAuctionArticles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAuctionArticles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  doLiveBid: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`/auctions/${data.auctionId}/articles/${data.articleId}/bid`, {
          bid: data.bid,
          type: data.type,
          timestamp: Date.now()
        })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: doLiveBid", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: doLiveBid", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  downloadImages: async (id: number): Promise<any> => //unused, so no dispatcher
    new Promise((resolve, reject) => {
      httpClient
        .post(`/auctions/${id}/images`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: downloadImages", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: downloadImages", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  action: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`/auctions/${data.auctionId}/action`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: action", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: action", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  jumpToArticle: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`/auctions/${auctionId}/articles/${articleId}/jump-to-article`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: jumpToArticle", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: jumpToArticle", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  getAdminKnockdownsPerAuction: async (auctionId: number, csv: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/knockdowns`, csv ? {csv: true} : null)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAdminKnockdownsPerAuction", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAdminKnockdownsPerAuction", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  removeCommissionBids: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles/all/commission-bid`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeCommissionBids", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeCommissionBids", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  updateCommissionBids: async (auctionId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}/articles/all/commission-bid-status`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateCommissionBids", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateCommissionBids", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  signup: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/sign-up', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: signup", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: signup", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  confirmUserRegistration: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/confirmation-account', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: confirmUserRegistration", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: confirmUserRegistration", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  resetPassword: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/request-reset-password', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: resetPassword", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: resetPassword", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  resendActivationEmail: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/request-resend-activation-email', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: resendActivationEmail", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: resendActivationEmail", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  confirmResetPassword: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/reset-password', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: confirmResetPassword", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: confirmResetPassword", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
  logout: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/logout', null, null, 200)
        .then(rs => {
          // Remove Authorization header
          httpClient.removeHeader('Authorization')
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: logout", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: logout", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getUserProfile: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users/me/profile')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getUserProfile", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserProfile", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getNotification: async (userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/notifications`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getNotification", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getNotification", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  markReadNotification: async (userId: number, id: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`/users/${userId}/notifications/${id}/mark-read`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: markReadNotification", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: markReadNotification", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateUserProfile: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put('users/me/profile', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateUserProfile", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateUserProfile", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getUserSettings: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users/configs?fields=builtinStreaming,general,localization,mailing,hotkeys,features,currencies')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getUserSettings", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserSettings", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getSystemCurrencies: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users/configs?fields=currencies')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getSystemCurrencies", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getSystemCurrencies", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getAppSettings: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('app-settings?fields=general,localization,builtinStreaming,features')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAppSettings", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAppSettings", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  searchArticles: async (query: string, lang: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('articles?query=' + query + "&lang=" + lang)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: searchArticles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: searchArticles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  checkIfAuctionArticlesUpdated: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles`, { getLastUpdatedTimestamp: true })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: checkIfAuctionArticlesUpdated", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: checkIfAuctionArticlesUpdated", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  checkIfAuctionArticlesUpdatedGuest: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`guest/auctions/${auctionId}/articles`, { getLastUpdatedTimestamp: true })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: checkIfAuctionArticlesUpdatedGuest", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: checkIfAuctionArticlesUpdatedGuest", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getAuctionArticlesGuest: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`guests/auctions/${auctionId}/articles`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAuctionArticlesGuest", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAuctionArticlesGuest", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getStatistics: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/stats`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getStatistics", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getStatistics", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getInquiries: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('admin/inquiries')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getInquiries", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getInquiries", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getArticlesDynamic: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles?dynamicData=true`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getArticlesDynamic", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getArticlesDynamic", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getGuestArticlesDynamic: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`guests/auctions/${auctionId}/articles?dynamicData=true`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getGuestArticlesDynamic", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getGuestArticlesDynamic", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getArticleTimedStatus: async (auctionId: number, articleId: number | string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles/${articleId}/timed-status`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getArticleTimedStatus", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getArticleTimedStatus", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  placeTimedBid: async (auctionId: number, articleId: number, bid: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}/articles/${articleId}/timed-bid`, { maximum_bid: bid })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: placeTimedBid", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: placeTimedBid", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getCategory: async (auctionId: any): Promise<any> => //TODO if used, add dispatcher
    new Promise((resolve, reject) => {
      httpClient
        .get(`categories/${auctionId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getCategory", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getCategory", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getCategories: async (getLastUpdatedTimestamp: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('categories', getLastUpdatedTimestamp ? {getLastUpdatedTimestamp: true} : null)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getCategories", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getCategories", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getCategoriesGuest: async (getLastUpdatedTimestamp: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('guests/categories', getLastUpdatedTimestamp ? {getLastUpdatedTimestamp: true} : null)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getCategoriesGuest", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getCategoriesGuest", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getWishList: async (auctionId: any, userId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/auctions/${auctionId}/wishlist`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getWishList", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getWishList", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getBidTransactions: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/bid-transactions`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getBidTransactions", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getBidTransactions", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getConfigFields: async (fields: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/configs?fields=${fields}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getConfigFields", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getConfigFields", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getEmailTemplate: async (name: string, lang: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/configs/mail-templates?name=${name}&lang=${lang}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getEmailTemplate", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getEmailTemplate", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateConfigs: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put('admin/configs', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateConfigs", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateConfigs", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateEmailTemplate: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put('admin/configs/mail-templates', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateEmailTemplate", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateEmailTemplate", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  instantBuy: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}/articles/${articleId}/instant-buy`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: instantBuy", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: instantBuy", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  // Admin requests
  uploadImage: async (data: any, headers: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('admin/upload-image', data, headers)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: uploadImage", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: uploadImage", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getStreamStats: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('stream/stats')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getStreamStats", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getStreamStats", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getVersions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('status/versions')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getVersions", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getVersions", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getStatus: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('status')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getStatus", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getStatus", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getStaticPage: async (pageName: string, lang: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/configs/static-pages?name=${pageName}&lang=${lang}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getStaticPage", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getStaticPage", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  sendTestEmail: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('admin/test-email', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: sendTestEmail", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: sendTestEmail", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getLogs: async (itemPerPage: number, skip: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/logs?take=${itemPerPage}&skip=${skip}`) //TODO use one param argument
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getLogs", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getLogs", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getAuctionData: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAuctionData", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAuctionData", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateImageCache: async (auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`admin/update-image-cache/${auctionId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateImageCache", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateImageCache", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  validateImages: async (auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/validate-images`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: validateImages", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: validateImages", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  testMediaServer: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('admin/test-media-server', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: testMediaServer", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: testMediaServer", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  // User requests

  setCommissionBids: async (auctionId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`/auctions/${auctionId}/articles/all/commission-bid`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: setCommissionBids", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: setCommissionBids", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  validateCommissionBids: async (auctionId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`/auctions/${auctionId}/articles/all/validate-commission-bids`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: validateCommissionBids", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: validateCommissionBids", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getAllCommissionBids: async (auctionId: number, getUserData: boolean): Promise<any> => {
    if (getUserData) {
      return new Promise((resolve, reject) => {
        httpClient
          .get(`auctions/${auctionId}/articles/all/commission-bid?getUserData=1`)
          .then(rs => {
            resolve(rs);
          })
          .catch(async err => {
            if (err.response?.data) {
              if (localFeatures.useSentry) {
                Sentry.setContext("Backend REST call failed: getAllCommissionBids", {
                  data: JSON.stringify(err.response.data)
                });
                Sentry.captureException(err);
              }
              if (localFeatures.useTelemetry) {
                await sendTelemetry("Backend REST call failed: getAllCommissionBids", 500, JSON.stringify(err.response.data));
              }
            }
            reject(err.response);
          });
      })
    } else {
      return new Promise((resolve, reject) => {
        httpClient
          .get(`auctions/${auctionId}/articles/all/commission-bid`)
          .then(rs => {
            resolve(rs);
          })
          .catch(async err => {
            if (err.response?.data) {
              if (localFeatures.useSentry) {
                Sentry.setContext("Backend REST call failed: getAllCommissionBids", {
                  data: JSON.stringify(err.response.data)
                });
                Sentry.captureException(err);
              }
              if (localFeatures.useTelemetry) {
                await sendTelemetry("Backend REST call failed: getAllCommissionBids", 500, JSON.stringify(err.response.data));
              }
            }
            reject(err.response);
          });
      })
    }
  },
  getAuctions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('auctions')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAuctions", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAuctions", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getGuestAuctions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('guests/auctions')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getGuestAuctions", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getGuestAuctions", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getUserKnockdowns: async (auctionId: number, userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/knockdowns/${userId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getUserKnockdowns", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserKnockdowns", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  sendEmailInquiry: async (userId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`users/${userId}/email-inquiry`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: sendEmailInquiry", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: sendEmailInquiry", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  requestSignup: async (auctionId: number, userId: number, bidLimit?: number, receiveStartReminderEmail?: boolean): Promise<any> => {
    let payload = {}
    if (bidLimit !== null) {
      payload = {
        bidLimit: bidLimit
      };
    }
    if (receiveStartReminderEmail !== undefined) {
      payload = {
        ...payload,
        notificationAuctionStart: receiveStartReminderEmail
      }
    }
    return new Promise((resolve, reject) => {
      httpClient
        .post(`users/${userId}/auctions/${auctionId}/request-signup`, payload)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: requestSignup", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: requestSignup", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    })
  },

  getUserBidderNumbers: async (userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/bidder-numbers`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getUserBidderNumbers", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserBidderNumbers", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getUserBidderNumbersWithState: async (userId: any, state: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/bidder-numbers?state=${state}&getUserData=true`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getUserBidderNumbersWithState", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserBidderNumbersWithState", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getAuctionBidderNumbersWithState: async (auctionId: number, state: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/users/${state}?getUserToAuctionData=true`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAuctionBidderNumbersWithState", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAuctionBidderNumbersWithState", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateUserBidderNumbers: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put('users/all/bidder-numbers', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateUserBidderNumbers", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateUserBidderNumbers", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  removeFromWishList: async (auctionId: number, userId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`users/${userId}/auctions/${auctionId}/wishlist/${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeFromWishList", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeFromWishList", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  addToWishList: async (auctionId: any, userId: any, articleId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`users/${userId}/auctions/${auctionId}/wishlist/${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: addToWishList", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: addToWishList", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateCategory: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`categories/${data.id}`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateCategory", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateCategory", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  createCategory: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('categories', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: createCategory", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: createCategory", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  deleteCategory: async (categoryId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`categories/${categoryId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: deleteCategory", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: deleteCategory", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getKnockdownsArticleSlider: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/knockdowns-article-slider`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getKnockdownsArticleSlider", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getKnockdownsArticleSlider", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  removeBidStep: async (auctionId: number, bidStepId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`auctions/${auctionId}/bid-steps/${bidStepId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeBidStep", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeBidStep", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  createBidStep: async (auctionId: number, data: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/bid-steps`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: createBidStep", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: createBidStep", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateBidStep: async (auctionId: number, bidSteps: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/bid-steps`, { bidSteps, recreate: true })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateBidStep", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateBidStep", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateAuction: async (auctionId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateAuction", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateAuction", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  createAuction: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auctions', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: createAuction", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: createAuction", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateArticle: async (auctionId: number, articleId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}/articles/${articleId}`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateArticle", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateArticle", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  createArticle: async (auctionId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/articles`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeArticle", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeArticle", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  removeArticle: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`auctions/${auctionId}/articles/${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeArticle", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeArticle", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  importArticles: async (auctionId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/articles/import`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: importArticles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: importArticles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getArticleImages: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles/${articleId}/images`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getArticleImages", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getArticleImages", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getUsersLastUpdatedTimestamp: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users', { getLastUpdatedTimestamp: true })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getUsersLastUpdatedTimestamp", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUsersLastUpdatedTimestamp", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getUsers: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getUsers", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUsers", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getEmailsStatus: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('admin/emails-sent')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getEmailsStatus", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getEmailsStatus", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getUserData: async (userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getUserData", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserData", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  removeUser: async (userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`users/${userId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeUser", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeUser", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  updateUser: async (userId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`users/${userId}`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: updateUser", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateUser", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  createUser: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('users', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: createUser", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: createUser", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  importUsers: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('users/import', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: importUsers", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: importUsers", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  stopArticle: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${data.auctionId}/articles/${data.articleId}/end-timed-article`, { force: data.force })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: stopArticle", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: stopArticle", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  startArticle: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${data.auctionId}/articles/${data.articleId}/start-timed-article`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: startArticle", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: startArticle", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  stopAuction: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${data.auctionId}/stop`, { forceCompleted: data.force })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: stopAuction", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: stopAuction", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  startAuction: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/start`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: startAuction", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: startAuction", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getFiles: async (userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/files`, { action: 'list' })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getFiles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getFiles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getFilesBackup: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users/backend/files?action=list&dir=backup')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getFilesBackup", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getFilesBackup", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getFile: async (userId: number, filename: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .getFile(`users/${userId}/files`, { action: `file_${filename}` })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getFile", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getFile", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getAdminFile: async (userId: number, filename: string, dir: string): Promise<any> => {
    let params: any = {
      action: `file_${filename}`
    }
    if (dir) {
      params = { ...params, dir }
    }
    return new Promise((resolve, reject) => {
      httpClient
        .getFile(`users/${userId || 'backend'}/files`, params)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAdminFile", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAdminFile", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    })
  },

  exportFiles: async (type: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('/admin/configuration', { type })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: exportFiles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: exportFiles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  importAdminFiles: async (type: string, file: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .postFile(`/admin/configuration?type=${type}`, file)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: importAdminFiles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: importAdminFiles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  restoreAdminFile: async (filename: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('/admin/restoreConfiguration', { filename })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: restoreAdminFile", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: restoreAdminFile", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  sendFiles: async (userId: number, payload: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`users/${userId}/files`, payload)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: sendFiles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: sendFiles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  removeFile: async (userId: number, filename: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`users/${userId}/files?action=file_${filename}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeFile", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeFile", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  removeAdminFile: async (userId: number, filename: string, dir: string): Promise<any> => {
    let params = `action=file_${filename}`
    if (dir) params += '&dir=backup'
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`users/${userId || 'backend'}/files?${params}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeAdminFile", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeAdminFile", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    })
  },

  disconnectUser: async (userId: number, block: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('admin/disconnectClient', { userId, block })
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: disconnectUser", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: disconnectUser", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  loginWithFacebook: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('auth/login-facebook')
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: loginWithFacebook", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: loginWithFacebook", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  signIn: async (email: string, password: string): Promise<any> =>
    new Promise((resolve, reject) => {
      let postData = {
        email,
        password
      };
      httpClient
        .post('auth/login', postData)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: signIn", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: signIn", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getArticleFiles: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/backend/files?auctionId=${auctionId}&articleId=${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getArticleFiles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getArticleFiles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  uploadArticleFiles: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('users/backend/files-raw', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: uploadArticleFiles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: uploadArticleFiles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  removeArticleFiles: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`/users/backend/files?action=article&auctionId=${auctionId}&articleId=${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: removeArticleFiles", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeArticleFiles", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  getSingleImage: async (auctionId: number, articleId: number, imageIndex: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .getFile(`/users/backend/files?action=download&auctionId=${auctionId}&articleId=${articleId}&imageIndex=${imageIndex}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getSingleImage", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getSingleImage", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  joinLiveAuction: async (auctionId: number, browserTabFingerprint: string): Promise<any> =>
    new Promise((resolve, reject) => {
      const payload = {
        browserTabFingerprint
      };
      httpClient
        .post(`auctions/${auctionId}/join`, payload)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: joinLiveAuction", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: joinLiveAuction", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  leaveLiveAuction: async (auctionId: number, browserTabFingerprint: string): Promise<any> =>
    new Promise((resolve, reject) => {
      const payload = {
        browserTabFingerprint
      };
      httpClient
        .post(`auctions/${auctionId}/leave`, payload)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: leaveLiveAuction", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: leaveLiveAuction", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  exportUsers: async (filename: string, auctionCode?: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(auctionCode !== undefined ? `export/users?auctionCode=${auctionCode}`: `export/users`)
        .then(rs => {
          const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), rs.data], {type: "text/csv;charset=utf-8"});
          saveAs(blob, filename);
          resolve(true);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: exportUsers", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: exportUsers", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  exportCommissionBids: async (filename: string, auctionCode: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`export/commissionBids?auctionCode=${auctionCode}`)
        .then(rs => {
          const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), rs.data], {type: "text/csv;charset=utf-8"});
          saveAs(blob, filename);
          resolve(true);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: exportCommissionBids", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: exportCommissionBids", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  exportKnockdowns: async (filename: string, auctionCode: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`export/knockdowns?auctionCode=${auctionCode}`)
        .then(rs => {
          const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), rs.data], {type: "text/csv;charset=utf-8"});
          saveAs(blob, filename);
          resolve(true);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: exportKnockdowns", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: exportKnockdowns", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  validateTfa: async (email: string, tfaToken: number): Promise<any> =>
    new Promise((resolve, reject) => {
      let postData = {
        email,
        tfa_code: tfaToken
      };
      httpClient
        .post(`auth/loginTfa`, postData)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: validateTfa", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: validateTfa", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),
    resendTfa: async (email: string): Promise<any> =>
      new Promise((resolve, reject) => {
        let postData = {
          email
        };
        httpClient
          .post(`auth/resendTfa`, postData)
          .then(rs => {
            resolve(rs);
          })
          .catch(async err => {
            if (err.response?.data) {
              if (localFeatures.useSentry) {
                Sentry.setContext("Backend REST call failed: resendTfa", {
                  data: JSON.stringify(err.response.data)
                });
                Sentry.captureException(err);
              }
              if (localFeatures.useTelemetry) {
                await sendTelemetry("Backend REST call failed: resendTfa", 500, JSON.stringify(err.response.data));
              }
            }
            reject(err.response);
          });
      }),

  getAuctionJoinStatus: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/me/userJoinStatus`)
        .then(rs => {
          resolve(rs);
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: getAuctionJoinStatus", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAuctionJoinStatus", 500, JSON.stringify(err.response.data));
            }
          }
          reject(err.response);
        });
    }),

  sendClientTelemetry: async (subject: string, returnCode: number, content: string): Promise<void> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`client-telemetry`, {subject, returnCode, content})
        .then(rs => {
          resolve();
        })
        .catch(async err => {
          if (err.response?.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend REST call failed: sendClientTelemetry", {
                data: JSON.stringify(err.response.data)
              });
              Sentry.captureException(err);
            }
            if (localFeatures.useTelemetry) {
              console.log(`Telemetry send failed for REST call: Subject ${subject}, ${JSON.stringify(err.response.data)}`);
            }
          }
          reject(err.response);
        });
    })
}

export default apiRequests // eslint-disable-line
